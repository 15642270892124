@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body,
#root {
  margin: 0;
  width: 100%;
  height: 100%;
}

::-webkit-scrollbar {
  width: 12px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #f1f1ee;
  border-radius: 4px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #dfded8;
  border-radius: 4px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #a7a295;
}

.scrollbar {
  scrollbar-width: thin;
  scrollbar-color: #a7a295 #f1f1ee;
}

.accent-info-color {
  color: #3b63f1;
}

@font-face {
  font-family: "Graphik-Regular";
  src: url("./fonts/Graphik-Regular.eot");
  src: local("☺"), url("./fonts/Graphik-Regular.woff") format("woff"),
    url("./fonts/Graphik-Regular.ttf") format("truetype"),
    url("./fonts/Graphik-Regular.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Graphik-Medium";
  src: url("./fonts/Graphik-Medium.eot");
  src: local("☺"), url("./fonts/Graphik-Medium.woff") format("woff"),
    url("./fonts/Graphik-Medium.ttf") format("truetype"),
    url("./fonts/Graphik-Medium.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Graphik-Semibold";
  src: url("./fonts/Graphik-Semibold.eot");
  src: local("☺"), url("./fonts/Graphik-Semibold.woff") format("woff"),
    url("./fonts/Graphik-Semibold.ttf") format("truetype"),
    url("./fonts/Graphik-Semibold.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}

.amplify-button {
  font-size: 16px !important;
  height: 50px !important;
  background: #571293 !important;
  border: 0px transparent !important;
  color: white !important;
  border-radius: 8px !important;
  margin-top: 10px !important;
}

.amplify-field-group__outer-end .amplify-button {
  height: 42px !important;
  margin-bottom: 10px !important;
  margin-top: 0px !important;
}

.amplify-field-group__outer-end
  .amplify-select__wrapper:not(:last-child)
  .amplify-select,
.amplify-field-group__outer-end--quiet .amplify-field-group__control,
.amplify-field-group__outer-end .amplify-field-group__control:not(:last-child),
.amplify-field-group__outer-start .amplify-select__wrapper .amplify-select,
.amplify-field-group__outer-start .amplify-field-group__control,
.amplify-field-group :not(:last-child) .amplify-input {
  border-bottom-right-radius: 0px !important;
  border-top-right-radius: 0px !important;
  border-top-left-radius: 8px !important;
  border-bottom-left-radius: 8px !important;
}

.amplify-input {
  background: #fafaf9 !important;
  border: 1px solid #cccbc2 !important;
  border-radius: 8px !important;
  overflow: hidden !important;
  flex: none !important;
  order: 1 !important;
  align-self: stretch !important;
  flex-grow: 0 !important;
  margin-bottom: 10px !important;
}

.amplify-field__show-password {
  border-bottom-right-radius: 8px !important;
  border-top-right-radius: 8px !important;
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  background: #fafaf9 !important;
  border-right: 1px solid #cccbc2 !important;
  border-top: 1px solid #cccbc2 !important;
  border-bottom: 1px solid #cccbc2 !important;
  color: #91908b !important;
}

.amplify-field__show-password:hover {
  color: #fafaf9 !important;
  border-right: 1px solid transparent !important;
  border-top: 1px solid transparent !important;
  border-bottom: 1px solid transparent !important;
}

form[data-amplify-authenticator-forcenewpassword]:after {
  content: "Password must include number, special character, lowercase and uppercase letter";
  font-size: 16px;
  color: #a7a295;
}

form[data-amplify-authenticator-forcenewpassword] .amplify-button--link {
  display: none;
}

form[data-amplify-authenticator-forcenewpassword] .amplify-button--primary {
  margin-bottom: 20px;
}

.amplify-input:hover,
.amplify-input:active,
.amplify-input:focus-visible {
  background: #fafaf9;
  border: 1px solid #110f0e !important;
  box-shadow: 0 0 #0000 !important;
}

.amplify-button--link {
  font-size: 16px !important;
  height: 42px !important;
  background: transparent !important;
  border: 0px transparent !important;
  color: #7122bf !important;
  font-weight: bold !important;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

.amplify-button:hover {
  background: #9140dd !important;
}

.amplify-button:focus {
  box-shadow: 0 0 #0000 !important;
}

.amplify-button--link:hover {
  background: transparent !important;
}

.data-amplify-authenticator-signin {
  border-radius: 8px !important;
}

.amplify-flex.amplify-tabs {
  display: none;
}

.amplify-alert--error {
  border-radius: 8px !important;
}

.amplify-heading--3 {
  font-size: 20px !important;
  font-weight: 500 !important;
}

div[data-amplify-router] {
  width: 60% !important;
  padding: 65px 0px 0px 0px;
  border: transparent !important;
  box-shadow: 0 0 #0000 !important;
  background-image: url("../src/assets/logos/logo.svg");
  background-repeat: no-repeat;
  background-position: center top !important;
}

div[data-amplify-theme] {
  width: 100% !important;
  height: 100% !important;
  background-image: url("../src/assets/logos/laptop_login.svg") !important;
  background-repeat: no-repeat !important;
  background-position: left center !important;
  background-attachment: fixed;
  display: flex;
  flex-direction: row-reverse;
  background: #e6ebf0;
}

div[data-amplify-container] {
  width: 100% !important;
  height: 100% !important;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background: white !important;
  background-image: url("../src/assets/logos/text.svg") !important;
  background-repeat: no-repeat !important;
  background-position: center 90% !important;
  background-attachment: fixed;
  padding-bottom: 100px;
  border-radius: 8px !important;
}

div[data-amplify-authenticator] {
  width: 50% !important;
  height: 100% !important;
  position: absolute;
  padding-top: 50px;
  padding-bottom: 10px;
  background: white !important;
  box-shadow: 0 0 #0000 !important;
}
.rcs-custom-scroll {
  min-height: 0;
  min-width: 0;
}
.rcs-custom-scroll .rcs-outer-container {
  overflow: hidden;
}
.rcs-custom-scroll .rcs-outer-container .rcs-positioning {
  position: relative;
}
.rcs-custom-scroll .rcs-outer-container:hover .rcs-custom-scrollbar {
  opacity: 1;
  transition-duration: 0.2s;
}
.rcs-custom-scroll .rcs-inner-container {
  overflow-x: hidden;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}
.rcs-custom-scroll .rcs-inner-container:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  height: 0;
  background-image: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.2) 0%,
    rgba(0, 0, 0, 0.05) 60%,
    rgba(0, 0, 0, 0) 100%
  );
  pointer-events: none;
  transition: height 0.1s ease-in;
  will-change: height;
}
.rcs-custom-scroll .rcs-inner-container.rcs-content-scrolled:after {
  height: 5px;
  transition: height 0.15s ease-out;
}
.rcs-custom-scroll.rcs-scroll-handle-dragged .rcs-inner-container {
  user-select: none;
}
.rcs-custom-scroll .rcs-custom-scrollbar {
  position: absolute;
  height: 100%;
  width: 6px;
  right: 3px;
  opacity: 0.1;
  z-index: 1;
  transition: opacity 0.4s ease-out;
  padding: 6px 0;
  box-sizing: border-box;
  will-change: opacity;
  pointer-events: none;
}
.rcs-custom-scroll .rcs-custom-scrollbar.rcs-custom-scrollbar-rtl {
  right: auto;
  left: 3px;
}
.rcs-custom-scroll.rcs-scroll-handle-dragged .rcs-custom-scrollbar {
  opacity: 1;
}
.rcs-custom-scroll .rcs-custom-scroll-handle {
  position: absolute;
  width: 100%;
  top: 0;
}
.rcs-custom-scroll .rcs-inner-handle {
  height: calc(100% - 12px);
  margin-top: 6px;
  background-color: #a7a2956e;
  border-radius: 3px;
}
